<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <!-- ACTIVATOR BUTTON -->
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Add Courts</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            color="color3 color3Text--text"
            class="mr-2"
            small fab
            v-on="{ ...tt, ...d }"
          >
            <v-icon small>fas fa-map-marker-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card color="grey lighten-3">
      <div>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>{{mode === 'wi' ? 'Work-In Courts' : 'Courts By Round'}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          @click.stop="dialog = false"
          fab small
          :loading="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      </div>
      <!-- NAV BUTTONS -->
      <v-card-text>
        <v-btn color="color3" x-small text @click.stop="mode='wi'" :disabled="mode === 'wi'">work in</v-btn>
        <v-btn color="color3" x-small text @click.stop="mode='br'" :disabled="mode === 'br'">By round</v-btn>
        <v-btn color="color3" x-small text @click.stop="mode='io'" :disabled="mode === 'io'">In Order</v-btn>
        <v-btn color="color3" x-small text @click.stop="mode='xx'" :disabled="mode === 'xx'">Clear All</v-btn>
      </v-card-text>
      <v-window v-model="window">
        <v-window-item :key="0">
          <v-card-text class="text-center">
            <v-text-field label="Courts" v-model="courts" @paste="onPaste"></v-text-field>
            <v-expand-transition>
              <div v-if="mode === 'br'">
                <v-select
                  label="Round"
                  v-model="round"
                  :items="rounds"
                ></v-select>
                <date-time-picker
                  :datetime.sync="startTime"
                  label="Play Start"
                ></date-time-picker>
              </div>
              <template v-else-if="mode === 'io'">
                <div>
                  <v-checkbox label="Repeat" v-model="repeat" :value="true"></v-checkbox>
                  <v-checkbox label="Skip Already Courted Matches" v-model="skipCourted" :value="true"></v-checkbox>
                </div>
              </template>
              <v-checkbox label="Straight Down" v-model="str8Down" :value="true" v-else></v-checkbox>
            </v-expand-transition>
            <v-btn
              color="success"
              @click.stop="go"
              :disabled="!valid"
              :loading="loading"
            >go</v-btn>
            <div class="caption" :class="!valid ? 'error--text': null" v-if="mode === 'wi'">This courting method currently only supports the following number of courts: {{validCounts | formatArray}}</div>
          </v-card-text>
        </v-window-item>
        <v-window-item :key="1">
          <v-card-text class="text-center">
            <div class="d-flex justify-center">
              <v-checkbox label="Clear All Courts" v-model="clearCourts" color="success" class="shrink" hide-details></v-checkbox>
            </div>
            <div class="d-flex justify-center">
              <v-checkbox label="Clear All Times" v-model="clearTimes" color="success" class="shrink" hide-details></v-checkbox>
            </div>
            <div class="d-flex justify-center">
              <v-checkbox label="I am sure" v-model="sure" color="success" class="shrink "></v-checkbox>
            </div>
            <v-btn color="success" :disabled="!clearReady" @click.stop="doClearing">Clear All</v-btn>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import { firstBy } from 'thenby'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import moment from 'moment'
const sortFn = (a, b) => {
  return a === b ? 0 : a < b ? -1 : 1
}

export default {
  props: ['bracket'],
  data () {
    return {
      dialog: false,
      loading: false,
      courts: null,
      mode: 'wi',
      round: null,
      startTime: null,
      window: 0,
      clearCourts: false,
      clearTimes: false,
      sure: false,
      str8Down: false,
      pasted: false,
      repeat: false,
      skipCourted: false
    }
  },
  computed: {
    clearReady () {
      return this.sure && (this.clearCourts || this.clearTimes)
    },
    rounds () {
      return [...new Set(this.bracket.matches.map(m => m.round + 1))]
    },
    validCounts () {
      return this.bracket.winners.map(m => m.matches.length)
    },
    calcCourts () {
      if (!this.courts) return []
      let results = this.courts.includes(',') ? this.courts.split(',') : this.courts.split(' ')
      const ranges = results.filter(f => f.includes('-'))
      ranges.forEach(r => {
        results = results.filter(f => f !== r)
        const x = r.split('-')
        let a = +x[0]
        const b = +x[1]
        if (!isNaN(a) && !isNaN(b)) {
          if (a < b) {
            for (a; a <= b; a++) {
              results.push(`${a}`.trim())
            }
          } else {
            for (a; a >= b; a--) {
              results.push(`${a}`.trim())
            }
          }
        }
      })

      // return [...new Set(results)]
      return results
    },
    valid () {
      return this.mode === 'wi' ? this.validCounts.includes(this.calcCourts.length) : true
    }
  },
  methods: {
    go () {
      if (this.mode === 'wi') {
        return this.str8Down ? this.goStr8() : this.goWi()
      }
      if (this.mode === 'io') {
        return this.goIo()
      }
      return this.goBr()
    },
    goStr8 () {
      const assignCourt = (c, m, i, dto, matches) => {
        const done = dto.findIndex(f => f.number === m.number) > -1
        if (done) return

        dto.push({
          number: m.number,
          court: c,
          courtOrder: i++,
          bracketId: m.bracketId,
          id: m.id
        })
        var next = this.bracket.matches.find(f => f.homeFeeder === m.number)
        if (next) {
          assignCourt(c, next, i, dto, matches)
        }
        next = this.bracket.matches.find(f => f.awayFeeder === m.number)
        if (next) {
          assignCourt(c, next, i, dto, matches)
        }
        return dto
      }
      const matches = []
      this.bracket.matches.forEach(m => {
        if (!m.isBye) {
          if (!m.homeFeeder && !m.awayFeeder) {
            matches.push(m)
          } else {
            const pH = m.homeFeeder && matches.find(f => f.number === m.homeFeeder)
            const pA = m.awayFeeder && matches.find(f => f.number === m.awayFeeder)
            if ((!pH || pH.isBye) && (!pA || pA.isBye)) {
              matches.push(m)
            }
          }
        }
      })
      matches.sort(firstBy('number'))
      console.log(matches)
      const courts = [...this.calcCourts]
      const dto = []
      let i = 0
      for (i; i < courts.length; i++) {
        if (i < matches.length) {
          const court = courts[i]
          const match = matches[i]
          const n = 1
          assignCourt(court, match, n, dto, matches)
        }
      }
      console.log(dto)
      this.saveEdit(dto)
    },
    goWi () {
      const round = this.bracket.winners.find(f => f.matches.length === this.calcCourts.length)
      console.log(round)
      const getMatchNumbers = (m, ids) => {
        ids.add(m.number)
        if (m.awayFeeder) {
          const af = this.bracket.matches.find(f => f.number === m.awayFeeder)
          af && getMatchNumbers(af, ids)
        }
        if (m.homeFeeder) {
          const hf = this.bracket.matches.find(f => f.number === m.homeFeeder)
          hf && getMatchNumbers(hf, ids)
        }
      }
      const groups = round.matches.map(m => {
        const ids = new Set()
        getMatchNumbers(m, ids)
        const matches = [...ids].map(n => {
          const m = this.bracket.matches.find(f => f.number === n)
          const seeds = [m.homeSeed, m.awaySeed, m.loserMap.hi].filter(f => f > 0)
          const seed = Math.min(...seeds)
          return {
            n: n,
            match: m,
            seed: seed,
            round: m.round
          }
        })
        return {
          matches: matches,
          seed: Math.min(...matches.map(m => m.seed))
        }
      })

      this.bracket.matches.filter(f => f.round > round.number).forEach(m => {
        const s = Math.min(...[m.homeSeed, m.awaySeed, m.loserMap.hi].filter(f => f > 0))
        var g = groups.find(f => f.seed === s)
        if (g) {
          g.matches.push({
            n: m.number,
            match: m,
            seed: s,
            round: m.round
          })
        }
      })

      groups.forEach(g => {
        g.matches = g.matches.filter(f => !f.match.isBye).sort(function (a, b) {
          return sortFn(a.round, b.round) || sortFn(a.seed, b.seed)
        })
      })

      groups.sort(function (a, b) {
        return sortFn(a.seed, b.seed)
      })

      console.log(groups)
      var dto = []
      groups.forEach((g, i) => {
        let n = 1
        dto.push(...g.matches.map(m => {
          return {
            number: m.n,
            court: this.calcCourts[i],
            courtOrder: n++,
            bracketId: m.match.bracketId,
            id: m.match.id
          }
        }))
      })

      console.log(dto)
      this.saveEdit(dto)
    },
    goBr () {
      var roundMatches = this.bracket.matches.filter(f => f.round === (this.round - 1) && !f.isBye).sort(firstBy('number'))
      const dto = []
      let x = 0
      const noCourt = this.calcCourts.length === 0
      const noTime = !this.startTime
      roundMatches.forEach((m, i) => {
        dto.push({
          number: m.number,
          court: noCourt ? null : this.calcCourts[x++],
          bracketId: this.bracket.id,
          id: m.id,
          startTime: noTime ? null : this.startTime
        })
        if (x === this.calcCourts.length && !noCourt) {
          x = 0
          const m = this.bracket.winnersMatchSettings.minutesPerMatch
          const s = moment(this.startTime).add(m, 'm')
          this.startTime = s.format('YYYY-MM-DDTHH:mm:ss')
        }
      })
      console.log(dto)
      this.saveEdit(dto)
    },
    goIo () {
      const dto = this.bracket.matches.filter(f => !f.isBye).map(m => {
        return {
          number: m.number,
          court: m.court,
          courtOrder: m.courtOrder,
          bracketId: m.bracketId,
          id: m.id
        }
      })
      dto.sort(firstBy('number'))
      const courts = [...this.calcCourts]
      let i = 0

      dto.forEach(m => {
        if (i < courts.length && !(this.skipCourted && m.court)) {
          m.court = courts[i++]
          if (i >= courts.length && this.repeat) {
            i = 0
          }
        }
      })

      this.autoOrder(dto)
      console.log(dto)
      this.saveEdit(dto)
    },
    autoOrder (matches) {
      const courts = [...new Set(matches.filter(f => f.court).map(m => m.court))]
      courts.forEach(c => {
        const m = matches.filter(f => f.court === c).sort(function (a, b) {
          return sortFn(a.courtOrder, b.courtOrder) || sortFn(a.number, b.number)
        })

        m.forEach((f, i) => {
          f.courtOrder = i + 1
        })
      })
    },
    saveEdit (dto) {
      this.loading = true
      this.$VBL.post.matches(dto)
        .then(r => { this.reset() })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
        })
    },
    reset () {
      this.courts = null
      this.dialog = false
      this.window = 0
      this.sure = false
      this.clearCourts = false
      this.clearTimes = false
      this.round = null
      this.startTime = null
    },
    doClearing () {
      this.loading = true
      this.$VBL.post.matcheReset(this.bracket.id, this.clearCourts, this.clearTimes)
        .then(r => { this.reset() })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
        })
    },
    onPaste () {
      this.pasted = true
    }
  },
  watch: {
    mode: function (n, o) {
      if (n === 'xx') {
        this.window = 1
      } else if (o === 'xx') {
        this.window = 0
      }
    },
    courts: function (n) {
      if (this.pasted) {
        const v = this.courts || ''
        this.courts = v.replaceAll(' ', '')
        this.pasted = false
      }
    }
  },
  components: {
    DateTimePicker
  }
}
</script>
